import React, { useState, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from 'query-string'
import Turnstile from '../../components/Turnstile';
import { setPageClass, setUpHeader } from "../../store/features/slice-ui";
import { register } from "../../store/features/slice-account";
import { resetErrors } from "../../store/features/slice-api";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Input from "../../components/Form/Input";
import Checkbox from "../../components/Form/Checkbox";
import InputPhone from "../../components/Form/Phone";
import Button from "../../components/Button";
import Select from "../../components/Form/Select";
import G from "../../app/globals";
import FlexibleListItem from '../../components/flexible/List/Item';
import FlexibleVideo from '../../components/flexible/Video';
import { ReactComponent as Check } from '../../assets/images/icons/check_solid_clean.svg';

const RegisterForm = (props) => {
  const dispatch = useDispatch();
  const errors = useSelector((state) => state.api.errors.register);
  const { search } = useLocation()
  const queryParams = queryString.parse(search);
  const errorsRef = useRef();

  useEffect(() => {
    dispatch(setPageClass("page-register no-bottom-menu"));
    dispatch(setUpHeader({
      logo: true,
      sideNav: false
    }));
  }, [dispatch]);

  const initialFormState = {
    _first_name: props.location.state?._first_name || queryParams?._first_name || "",
    _last_name: props.location.state?._last_name || queryParams?._last_name || "",
    _email: props.location.state?._email || queryParams?._email || "",
    _mobile_number: queryParams?._mobile || "",
    _postcode: queryParams?._postcode || "",
    _pass: "",
    _salutation: props.location.state?._salutation || "",
    _ahpra_number: "",
    _marketing: false,
    _consent: false
  };
  const [form, setForm] = useState(initialFormState);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.type === 'checkbox' ? e.target.checked : e.target.value,
    });

    if (e.target.name === '_pass') {
      e.target.setCustomValidity('');
      const isWeakPassword = G.WEAK_PASSWORDS.includes(e.target.value);
      e.target.setCustomValidity(!new RegExp("^(?=.*[a-z])(?=.*[A-Z])(?=.*\\d)(?=.*[!@#$%^&*()_+\\-=]).{9,}$").test(e.target.value) ? 'Password must be at least 9 characters long, include uppercase and lowercase letters, a number, and a special character from !@#$%^&*()_+-="' : '');
      if (isWeakPassword) {
        e.target.setCustomValidity('This password is weak and widely used. Please choose a stronger one.');
      }
    }
    if (e.target.name === '_ahpra_number') {
      e.target.setCustomValidity(e.target.validity.patternMismatch ? 'AHPRA number must start with 3 letters followed by 10 numbers.' : '');
    }
  };
  const handleSelectChange = (source, payload, isMulti) => {
    setForm({
      ...form,
      [source]: isMulti ? payload.map(item => item.value) : payload.value
    });
  };

  const setTurnstileToken = (token) => {
    setForm({
      ...form,
      cf_turnstile_response: token,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(register(form));
  };

  useEffect(() => {
    if (errors && Object.values(errors).length > 0) {
      errorsRef.current?.scrollIntoView({ behavior: "smooth" });
    }
  }, [errors, errorsRef])

  useEffect(() => {
    //empty previous errors from redux
    dispatch(resetErrors());
  }, [dispatch]);


  // Data

  const registerChecklist = [
    'Free to register',
    'Optimised for Desktop, Tablet or Mobile',
    'Content tailored to your preferences'
  ];

  const registerPoints = [
    {
      title: 'Referrals',
      description: 'Refer to an Icon Specialist',
      icon: 'refer'
    },
    {
      title: 'Education',
      description: 'Reach your yearly CPD requirements',
      icon: 'education'
    },
    {
      title: 'Events',
      description: 'Gain access to exclusive Icon events',
      icon: 'calendar'
    },
    {
      title: 'News',
      description: 'Stay up to date with the latest industry news',
      icon: 'article'
    }
  ];


  return (
    <>

      <div className="register-form-wrap">
        <div className="register-form-inner">
          <div className="register-form-panel">

            <PageHead
              className="page-register--head"
              title="Register a new account"
            />

            <PageContent className="page-register--content">
              <div ref={errorsRef}></div>
              {errors && errors.length > 0 ?
                <div className="form-errors">
                  {errors.map((error, index) => (
                    <div key={index} className="form-errors--item">{error.message}</div>
                  ))}
                </div>
                : null}
              <form onSubmit={handleSubmit}>
                <Select
                  type={"text"}
                  name={"_salutation"}
                  title={"Salutation"}
                  options={G.SALUTATIONS_OPTIONS}
                  value={form._salutation ? ({ label: form._salutation, value: form._salutation }) : null}
                  onChange={(payload) => handleSelectChange('_salutation', payload)}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"_first_name"}
                  title={"First Name"}
                  value={form._first_name}
                  handleChange={handleChange}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"_last_name"}
                  title={"Last Name"}
                  value={form._last_name}
                  handleChange={handleChange}
                  isRequired
                />
                <InputPhone
                  name={"_mobile_number"}
                  title={"Phone Number"}
                  value={form._mobile_number}
                  handleChange={handleChange}
                  isRequired
                />
                <Input
                  type={"number"}
                  name={"_postcode"}
                  title={"Postcode"}
                  value={form._postcode}
                  handleChange={handleChange}
                  isRequired
                />
                <Input
                  type={"text"}
                  name={"_ahpra_number"}
                  title={"AHPRA Number"}
                  value={form._ahpra_number}
                  handleChange={handleChange}
                  isRequired
                  pattern="^[a-zA-Z]{3}\d{10}"
                />
                <Input
                  type={"email"}
                  name={"_email"}
                  title={"Email"}
                  value={form._email}
                  handleChange={handleChange}
                  isRequired
                />
                <Input
                  type={"password"}
                  name={"_pass"}
                  title={"Set your new password"}
                  value={form._pass}
                  handleChange={handleChange}
                  isRequired
                  autoComplete="new-password"
                />
                <Checkbox
                  title={"I acknowledge I have read and understood the Group Privacy Policy and agree to the collection, use and handling of my Personal Data in accordance with the policy. "}
                  name={"_consent"}
                  handleChange={handleChange}
                  isRequired
                />
                <Checkbox
                  title={"I agree to receive emails about the latest in cancer care, treatments and other updates from Icon Cancer Centre."}
                  name={"_marketing"}
                  handleChange={handleChange}
                />
                <Turnstile setToken={setTurnstileToken} />
                <Button
                  type={"submit"}
                  className="btn btn-primary btn-blue text-yellow full-width"
                  text="Register"
                />

              </form>
            </PageContent>

          </div>

          <div className="register-form-content">

            <FlexibleVideo
              src='/temp/IDA_Demo_Draft_v2.mp4'
              offset={false}
              controls={true}
              playing={false}
              playsinline={true}
              muted={false}
              loop={false}
            />

            <div className='flexible-cta--text-list'>
              {registerChecklist.map((item, index) => (
                <div className='flexible-cta--text-list-item' key={index}>
                  <Check />
                  {item}
                </div>
              ))}
            </div>

            <div>
              <h2>Get access to:</h2>
              <div className='flexible-list--items'>
                {registerPoints.map((item, index) => (
                  <FlexibleListItem
                    key={index}
                    title={item.title}
                    description={item.description}
                    icon={item.icon}
                    type={'small'}
                  />
                ))}
              </div>
            </div>
          </div>

        </div>
      </div>
    </>
  );
};

export default RegisterForm;