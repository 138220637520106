import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import parse from 'html-react-parser';
import { setPageClass, setUpHeader, openModal } from "../../store/features/slice-ui";
import { fetchArticles } from "../../store/features/slice-articles";
import { useBasePath } from "../../helpers/customHooks";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import FeedCard from "../../components/Cards/Feed";
import Pagination from "../../components/Pagination";
import ArticlesFilter from "../../components/Filters/ArticlesFilter";
import G from "../../app/globals";



const ArticlesList = () => {
    const dispatch = useDispatch();
    const history = useHistory();
    const basePath = useBasePath();
    const { page = 1 } = useParams();
    const [FilterOpen, setFilterOpen] = useState(false);
    let section = null;
    let pageTitle = null;
    switch (basePath) {
        case '/education/clinical-articles': 
            section = 'clinical-article';
            pageTitle = 'Clinical Articles';
        break;
        case '/clinical-resources/treatment-pathways': 
            section = 'treatment-pathways';
            pageTitle = 'Treatment Pathways';
        break;
        case '/clinical-resources/treatment-techniques': 
            section = 'treatment-techniques';
            pageTitle = 'Treatment Techniques';
        break;
        case '/research': 
            section = 'research';
            pageTitle = 'Research';
        break;
        case '/news': 
            section = 'news';
            pageTitle = 'Latest News';
        break;
        default: 
            section = 'clinical-article';
            pageTitle = 'Clinical Articles';
    }
    const articles = useSelector((state) => state.articles.list[section]['articles']);
    const pagination = useSelector((state) => state.articles.list[section]['pagination']);
    const filters = useSelector((state) => state.articles.filters[section]);
    const breakPoint = useSelector((state) => state.ui.breakPoint);
    const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
    const isFiltered = filters && (filters.topics.length > 0);
    const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
    const accountData = useSelector((state) => state.account.data);
    const options = useSelector((state) => state.references.options);

    useEffect(() => {
        dispatch(setPageClass("page-articles"));
        dispatch(setUpHeader({
            show: true,
            back: true,
            profile: true,
            search: true
        }));
    }, [dispatch]);

    useEffect(() => {
        let userPref = accountData && accountData.preferences ? accountData.preferences : null;
        if(isAuthenticated){
            if (((!articles && userPref) || (pagination && +pagination.current_page !== +page))) {
                dispatch(fetchArticles({ category: section, preferences: userPref , page: page }));
            }
        }
        else{
            if ((!articles || (pagination && +pagination.current_page !== +page))) {
                dispatch(fetchArticles({ category: section, preferences: null , page: page }));
            }
        }
        
    }, [dispatch, articles, section, pagination, page, accountData, isAuthenticated]);

    const handleModalText = () => {
        if(options && options.static_texts && options.static_texts.lm_popup_guest_access){
          return parse(options.static_texts.lm_popup_guest_access, G.HTML_PARSE_OPTIONS);
        }
        else{
          return G.DEFAULT_LM_POPUP;
        }
    }

    const renderArticles = () => {
        return articles ? articles.map(item => (
            <FeedCard
                key={item.id}
                image={item.thumbnail}
                title={parse(item.title)}
                description={parse(item.excerpt)}
                categories={item.format ? [{ 'title': section === 'news' ? 'News' : item.format, 'colour': 'yellow' , 'icon': item.format_icon ? item.format_icon : null}] : null}
                completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
                cpd={G.CPD_TEXT(item.cpd)}
                link={isAuthenticated ? ({ pathname: `/article/${item.slug}`,  state: { sourcePath: section }}) : item.user_type ? null : ({ pathname: `/article/${item.slug}`,  state: { sourcePath: section }})}
                Clicked={isAuthenticated ? null : item.user_type ? () => dispatch(openModal(handleModalText())) : null}
                date={section === 'news' ? item.published_date : null}
            />
        )) : null;
    }

    const handleFilterIconClick = () => {
        if(isDesktop){
          setFilterOpen(!FilterOpen);
        }
        else{
          history.push(`${basePath}/filter`)
        }
      }

    return (
        <>
            <PageHead
                className="page-articles--page-head"
                title={pageTitle}
                bg="blue"
                icon={section !== 'news' ? 'filter' : null}
                iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
                Clicked={handleFilterIconClick}
                secondaryIcon={isFiltered ? 'check' : null}
            />
            <PageContent className="page-articles--page-content">
                {isDesktop ? 
                    <ArticlesFilter 
                        className={`page-articles--filter in-page${FilterOpen ? '' : ' collapsed'}`}
                        title="Search"
                        filter={section}
                    />
                : null}
                <Section className="page-articles--list">
                    {renderArticles()}
                </Section>
                {pagination ? 
                    <Pagination 
                        total_pages={pagination.nb_pages}
                        current_page={pagination.current_page}
                    />
                : null}
                
            </PageContent>
        </>
    );
};

export default ArticlesList;
