import { ReactComponent as ReferIcon } from '../../../assets/images/icons/refer_clean.svg';
import { ReactComponent as EducationIcon } from '../../../assets/images/icons/education_clean.svg';
import { ReactComponent as CalendarIcon } from '../../../assets/images/icons/calendar_clean.svg';
import { ReactComponent as ArticleIcon } from '../../../assets/images/icons/article_clean.svg';

const iconMap = {
    refer: ReferIcon,
    education: EducationIcon,
    calendar: CalendarIcon,
    article: ArticleIcon,
};

const FlexibleListItem = (props) => {

    const Icon = iconMap[props.icon];

    return (
        <div className={`flexible-list--item is-type-${props.type}`}>
            <div className='flexible-list--item-icon'>
                <Icon />
            </div>
            <div className='flexible-list--item-text'>
                <h3>{props.title}</h3>
                <p>{props.description}</p>
            </div>
        </div>
    );
    
};

export default FlexibleListItem;