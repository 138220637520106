import React, {useState} from 'react';
import ReactPlayer from 'react-player'
import Loading from './Loading'

const Video = (props) => {
    const [isReady, setIsReady] = useState(false);

    const {vid, className, ...rest} = props

    const handleOnReady = () => {
        setIsReady(true);
    }
    return (
        <div className={`video ${className ? className : ''}${isReady ? '' : ' hidden'}`}>
            <ReactPlayer 
                url={vid}
                controls
                width="100%"
                height="100%"
                onReady={handleOnReady}
                {...rest}
            />
            {isReady ? null : <Loading />}
            
        </div>
    );
};
export default React.memo(Video);