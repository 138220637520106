import parse from "html-react-parser";
import Button from '../../Button';
import FlexibleListItem from './Item';

const FlexibleList = (props) => {

    return (
        <div className='flexible-list'>
            <div className='flexible-list--inner'>
                <div className='flexible-list--text'>
                    {props.title && <h2>{parse(props.title)}</h2>}
                    {props.description && parse(props.description)}
                    {props.cta && 
                        <Button 
                            className='btn btn-primary purple pill'
                            Clicked={() => props.cta.callback()}
                            text={props.cta.label}
                        />
                    }
                </div>
                <div className='flexible-list--items'>
                    {props?.items.map((item, index) => (
                        <FlexibleListItem
                            key={index}
                            title={item.title}
                            description={item.description}
                            icon={item.icon}
                            type={'default'}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
    
};

export default FlexibleList;