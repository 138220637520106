import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Redirect, useHistory, useParams } from "react-router-dom";
import parse from 'html-react-parser';
import { useBasePath } from "../../../helpers/customHooks";
import { setPageClass, setUpHeader, openModal } from "../../../store/features/slice-ui";
import { fetchLearningModules, fetchLearningModulesStarted, fetchLearningModulesCompleted } from "../../../store/features/slice-learning-modules";
import PageHead from "../../../components/PageHead";
import LearningModulesFilter from "../../../components/Filters/LearningModulesFilter";
import PageContent from "../../../components/PageContent";
import Section from "../../../components/Section";
import Tabs from "../../../components/Tabs";
import FeedCard from "../../../components/Cards/Feed";
import Pagination from "../../../components/Pagination";
import G from "../../../app/globals";


const LearningModulesIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const basePath = useBasePath();
  let section = null;
  switch (basePath) {
      case '/education/learning-modules': 
          section = 'all';
      break;
      case '/education/learning-modules/started': 
          section = 'started';
      break;
      case '/education/learning-modules/completed': 
          section = 'completed';
      break;
      default: 
          section = 'all';
  }
  const { page = 1 } = useParams();
  const [tabItems, setTabItems] = useState([]);
  const [FilterOpen, setFilterOpen] = useState(false);
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const accountData = useSelector((state) => state.account.data);
  const options = useSelector((state) => state.references.options);
  const learningModules = useSelector((state) => state.learningModules.list.lms);
  const learningModulesStarted = useSelector((state) => state.learningModules.started);
  const learningModulesCompleted = useSelector((state) => state.learningModules.completed);
  const pagination = useSelector((state) => state.learningModules.list.pagination);
  const breakPoint = useSelector((state) => state.ui.breakPoint);
  const isDesktop = !G.TABLET_BREAKPOINTS.includes(breakPoint);
  const filters = useSelector((state) => state.learningModules.filters);
  const isFiltered = filters && (filters.topics || filters.cpd_type);

    useEffect(() => {
      dispatch(setPageClass("page-lm-index"));
      dispatch(setUpHeader({
        show: true,
        back: true,
        profile: true,
        search: true,
        backTo: '/education'
      }));
    }, [dispatch]);

    useEffect(() => {
        let userPref = accountData && accountData.preferences ? accountData.preferences : null;
        if(isAuthenticated){
            if (((!learningModules && userPref) || (pagination && +pagination.current_page !== +page))) {
                dispatch(fetchLearningModules({ preferences: userPref , page: page }));
            }
        }
        else{
            if ((!learningModules || (pagination && +pagination.current_page !== +page))) {
                dispatch(fetchLearningModules({ preferences: null , page: page }));
            }
        }
    }, [dispatch, learningModules, pagination, page, accountData, isAuthenticated]);

    useEffect(() => {
        if (section === 'started' && (!learningModulesStarted || (learningModulesStarted && accountData && Object.values(accountData.lms).filter(lm => lm.progress > 0 && lm.progress < 100 && lm.parent).length !== learningModulesStarted.length))) {
            dispatch(fetchLearningModulesStarted());
        }
    }, [dispatch, learningModulesStarted, section, accountData]);

    useEffect(() => {
      if (section === 'completed' && (!learningModulesCompleted || (learningModulesCompleted && accountData && Object.values(accountData.lms).filter(lm => lm.progress === 100 && lm.parent).length !== learningModulesCompleted.length))) {
          dispatch(fetchLearningModulesCompleted());
      }
  }, [dispatch, learningModulesCompleted, section, accountData]);

    const handleFilterIconClick = () => {
      if(isDesktop){
        setFilterOpen(!FilterOpen);
      }
      else{
        history.push(`/education/learning-modules/filter`)
      }
    }

    const handleModalText = () => {
      if(options && options.static_texts && options.static_texts.lm_popup_guest_access){
        return parse(options.static_texts.lm_popup_guest_access, G.HTML_PARSE_OPTIONS);
      }
      else{
        return G.DEFAULT_LM_POPUP;
      }
    }

    const renderLearningModules = () => {
      let list_lms = [];
      switch(section){
        case 'all': list_lms = learningModules
        break;
        case 'started': list_lms = learningModulesStarted
        break;
        case 'completed': list_lms = learningModulesCompleted
        break;
        default: list_lms = learningModules;
      }
      return list_lms ? list_lms.map(item => (
          <FeedCard
              key={item.id}
              image={item.thumbnail}
              title={parse(item.title)}
              description={parse(item.excerpt)}
              categories={item.category ? [{ 'title': item.category, 'colour': 'green', 'icon': item.category_icon ? item.category_icon : null}] : null}
              link={isAuthenticated ? `/education/learning-module/${item.slug}` : item.user_type ? null : `/education/learning-module/${item.slug}`}
              Clicked={isAuthenticated ? null : item.user_type ? () => dispatch(openModal(handleModalText())) : null}
              completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
              cpd={G.CPD_TEXT(item.cpd)}
              authors={item.presenters}
              progress={accountData && accountData.lms && accountData.lms[item.id] ? accountData.lms[item.id].progress : 0}
          />
      )) : null;
  }
  useEffect(() => {
    let tabs = [];
    if(learningModules && learningModules.length > 0){
      tabs.push({
          index: 'all',
          text: 'All', 
          Clicked:() => history.push('/education/learning-modules')
      })
    }
    if(accountData && Object.values(accountData.lms).filter(lm => lm.progress > 0 && lm.progress < 100 && lm.parent).length > 0){
      tabs.push({
          index: 'started',
          text: 'Started', 
          Clicked:() => history.push('/education/learning-modules/started')
      })
    }
    if(accountData && Object.values(accountData.lms).filter(lm => lm.progress === 100 && lm.parent).length > 0){
      tabs.push({
          index: 'completed',
          text: 'Completed', 
          Clicked:() => history.push('/education/learning-modules/completed')
      })
    }
    setTabItems(tabs);
  }, [learningModules, history, accountData]);


  return (
    <>
      {tabItems.length === 1 && section !== 'all' ? <Redirect replace to="/education/learning-modules" /> : null}
      <PageHead 
        className="page-lm-index--page-head"
        title="Learning Modules"
        icon={section === 'all' ? 'filter' : null}
        iconText={isDesktop ? (FilterOpen ? 'Close' : 'Filter') : 'Filter'}
        Clicked={handleFilterIconClick}
        secondaryIcon={isFiltered ? 'check' : null}
      />
      <PageContent className="page-lm-index--page-content">
        {isDesktop ? 
          <LearningModulesFilter 
            className={`page-lm-index--filter in-page${FilterOpen ? '' : ' collapsed'}`}
            title="Search"
          />
        : null}
        {learningModules && tabItems.length > 1 ? 
        <Section className="page-lm-index--tabs">
          <Tabs className="page-lm-index--tabs-btn" activeItem={section} items={tabItems} />
        </Section>
        : null }
        <Section className="page-lm-index--list">
          {renderLearningModules()}
        </Section>
        {pagination && section === 'all' ? 
            <Pagination 
                total_pages={pagination.nb_pages}
                current_page={pagination.current_page}
            />
        : null}
      </PageContent>
    </>
  );
};

export default LearningModulesIndex;
