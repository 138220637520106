import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from 'react-router-dom';
import parse from 'html-react-parser';
import { setPageClass, setUpHeader, openModal } from "../../store/features/slice-ui";
import { fetchEducationData } from "../../store/features/slice-indexes";
import PageHead from "../../components/PageHead";
import PageContent from "../../components/PageContent";
import Section from "../../components/Section";
import StandardCard from "../../components/Cards/Standard";
import FeedCard from "../../components/Cards/Feed";
import CtaSubpageCard from "../../components/Cards/CtaSubPage";
import FeaturedCard from "../../components/Cards/Featured";
import { Word } from '../../components/Word';
import Carousel from "../../components/Carousel";
import Button from "../../components/Button";
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';
import { ReactComponent as LearningIcon } from '../../assets/images/icons/learning.svg';
import { ReactComponent as EventsIcon } from '../../assets/images/icons/calendar.svg';
import { ReactComponent as LocationIcon } from '../../assets/images/icons/location.svg';
import { ReactComponent as WifiIcon } from '../../assets/images/icons/wifi.svg';
import G from '../../app/globals';

const EducationIndex = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const isAuthenticated = useSelector((state) => state.auth.isAuthenticated);
  const options = useSelector((state) => state.references.options);
  const educationContent = useSelector((state) => state.indexes.education);
  const accountData = useSelector((state) => state.account.data);
  const requestState = useSelector((state) => state.indexes.requests.education);
  useEffect(() => {
    dispatch(setPageClass("page-education"));
    dispatch(setUpHeader({
      show: true,
      logo: true,
      profile: true,
      search: true
    }));
  }, [dispatch]);

  useEffect(() => {
    let userPref = accountData && accountData.preferences ? accountData.preferences : null;
    if(isAuthenticated){
      if (!educationContent && !requestState && userPref) {
        dispatch(fetchEducationData({ preferences: userPref }));
      }
    }
    else{
      if (!educationContent && !requestState) {
        dispatch(fetchEducationData({ preferences: null }));
      }
    }
  }, [dispatch, educationContent, requestState, isAuthenticated, accountData]);

  const handleModalText = () => {
    if(options && options.static_texts && options.static_texts.lm_popup_guest_access){
      return parse(options.static_texts.lm_popup_guest_access, G.HTML_PARSE_OPTIONS);
    }
    else{
      return G.DEFAULT_LM_POPUP;
    }
  }

  const renderEvents = () => {
    return educationContent && educationContent.events ? educationContent.events.filter(event => G.NEW_DATE(event.start.date) > new Date()).map(item => (
      <FeaturedCard 
        key={item.id}
        image={item.image_medium}
        title={parse(item.name)}
        categories={[{title: item.type, colour: 'purple', icon: item.type === 'Physical' ? <LocationIcon /> : <WifiIcon />}]}
        tags={item.cpd && item.cpd.type ? [{title: G.CPD_TEXT(item.cpd)}]: null}
        date={item.formattedDate}
        time={item.formattedTime}
        address={item.type === 'Physical' ? item.venue  : null}
        className="keen-slider__slide"
        link={`/education/event/${item.slug}`}
      />
    )) : null
  }

  const renderLeraningModules = () => {
    return educationContent && educationContent.learning_modules ? educationContent.learning_modules.map(item => (
      <FeedCard
            key={item.id}
            image={item.thumbnail}
            title={parse(item.title)}
            description={parse(item.excerpt)}
            categories={item.category ? [{ 'title': item.category, 'colour': 'green', 'icon': item.category_icon ? item.category_icon : null }] : null}
            link={isAuthenticated ? `/education/learning-module/${item.slug}` : item.user_type ? null : `/education/learning-module/${item.slug}`}
            Clicked={isAuthenticated ? null : item.user_type ? () => dispatch(openModal(handleModalText())) : null}
            completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
            cpd={G.CPD_TEXT(item.cpd)}
            authors={item.presenters}
      />
    )) : null
  }

  const renderClinicalArticles = () => {
    return educationContent && educationContent.articles ? educationContent.articles.map(item => (
      <StandardCard
          key={item.id}
          image={item.image_medium}
          title={parse(item.title)}
          stamp={item.format ? { 'title': item.format, 'colour': 'yellow', 'icon': item.format_icon ? item.format_icon : null } : null}
          link={isAuthenticated ? `/article/${item.slug}` : item.user_type ? null : `/article/${item.slug}`}
          Clicked={isAuthenticated ? null : item.user_type ? () => dispatch(openModal(handleModalText())) : null}
          completion={G.COMPLETION_TIME_TEXT(item.completion_time)}
          cpd={G.CPD_TEXT(item.cpd)}
          className="keen-slider__slide"
          authors={item.authors}
      />
    )) : null
  }

  const renderEducationFeatured = () => {
    let link = null;
    switch(educationContent.featured.post_type){
      case 'post' : link = `/article/${educationContent.featured.slug}`;
      break;
      case 'event' : link = `/event/${educationContent.featured.id}`;
      break;
      case 'learning-module' : link = `/education/learning-module/${educationContent.featured.slug}`;
      break;
      default: link = null;
    }
    return <StandardCard 
      image={educationContent.featured.image}
      title={parse(educationContent.featured.title)}
      description={parse(educationContent.featured.excerpt)}
      className="is-featured"
      categories={[
        {
          'title': 'Education',
          'icon': '<svg width="11" height="11" viewBox="0 0 11 11" fill="none" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M3.47663 6.39588C2.6939 5.79065 2.15754 4.66815 2.15754 3.76727C2.15754 2.42009 3.13845 1.63808 4.06072 1.60717C4.44299 1.60151 4.84663 1.69382 5.1489 1.85536C5.06936 1.29889 4.80936 0.871751 4.53799 0.567395L5.15936 0.300049C5.28799 0.477263 5.41936 0.791634 5.50526 1.16174C5.67936 0.859124 5.89072 0.612243 6.17572 0.45288C6.47981 0.282632 6.9839 0.273488 7.32026 0.334447C7.23526 0.620951 7.05799 0.993232 6.64254 1.23794C6.38708 1.38816 5.97572 1.46522 5.5589 1.43518C5.57799 1.5584 5.59208 1.68511 5.59845 1.81269C5.90845 1.65419 6.25299 1.60194 6.61799 1.60717C7.54026 1.63808 8.52117 2.42009 8.52117 3.76727C8.52117 4.66815 7.98481 5.79065 7.20208 6.39588H10.3394V7.26672H1.47572C1.35026 7.26672 1.24845 7.36425 1.24845 7.48442C1.24845 7.6046 1.35026 7.70213 1.47572 7.70213H10.3394V8.57297H1.93026C1.5539 8.57297 1.24845 8.86557 1.24845 9.22609C1.24845 9.58662 1.5539 9.87922 1.93026 9.87922H10.3394V10.75H1.93026C1.05208 10.75 0.339355 10.0673 0.339355 9.22609C0.339355 8.86469 0.471174 8.53247 0.690719 8.27122C0.474355 8.07311 0.339355 7.79357 0.339355 7.48442C0.339355 6.88355 0.848446 6.39588 1.47572 6.39588H3.47663ZM10.3394 9.4438H2.15754V9.00838H10.3394V9.4438ZM4.60117 2.6513C3.85436 3.18643 3.67617 4.15349 4.41026 5.52505C4.06708 5.35001 3.33299 4.63375 3.34117 3.80733C3.34845 3.07757 3.98345 2.61864 4.60117 2.6513Z" /></svg>',
          'colour': 'blue'
        }
      ]}
      link={isAuthenticated ? link : educationContent.featured.user_type ? null : link}
      Clicked={isAuthenticated ? null : educationContent.featured.user_type ? () => dispatch(openModal(handleModalText())) : null}
    />
  }
  
  return (
    <>
      <PageHead 
        className="page-education--page-head"
        title="Education"
        bg="blue"
      />
      <PageContent className="page-education--page-content">
        <Section className="page-education--ctas">
          <CtaSubpageCard 
            title={<Word value="Learning Modules" />}
            subtitle={<Word value="Educate through" />}
            icon={<LearningIcon />}
            link={`/education/learning-modules`}
          />
          <CtaSubpageCard 
            title={<Word value="Events" />}
            subtitle={<Word value="Connect through" />}
            icon={<EventsIcon />}
            link={`/education/events`}
          />
        </Section>
        {educationContent && educationContent.featured ? 
          <Section className="page-education--featured-card">
            <h2><Word value="Featured" /></h2>
            {renderEducationFeatured()}
          </Section>
        : null}
        {educationContent && educationContent.articles && educationContent.articles.length > 0 ? 
          <Section className="page-feeds--clinical-library" >
            <h2>
              <Word value="Clinical Articles" />
              <Button 
                className="btn-plain text-black"
                text="View all"
                icon={<ArrowRight />}
                Clicked={() => history.push('/education/clinical-articles')}
              />
            </h2>
            <Carousel
            className="page-feeds--clinical-library-carousel"
            options={{
              slidesPerView: 1,
              spacing: 20,
              breakpoints: {
                '(min-width: 480px)': {
                  slidesPerView: 2
                },
                '(min-width: 1200px)': {
                  slidesPerView: 3
                }
              }
            }}
            hasDots
            >
              {renderClinicalArticles()}
            </Carousel>
          </Section>
        : null }
        {educationContent && educationContent.events && educationContent.events.filter(event => G.NEW_DATE(event.start.date) > new Date()).length > 0 ? 
          <Section className="page-feeds--events" >
            <h2>
              <Word value="Upcoming Events" />
              <Button 
                className="btn-plain text-purple"
                text="View all"
                icon={<ArrowRight />}
                Clicked={() => history.push('/education/events')}
              />
            </h2>
            <Carousel
              className="page-feeds--events-carousel"
              options={{
                slidesPerView: 1,
                spacing: 20,
                breakpoints: {
                  '(min-width: 480px)': {
                    slidesPerView: 2
                  },
                  '(min-width: 1200px)': {
                    slidesPerView: 3
                  }
                }
              }}
              hasDots
            >
              {renderEvents()}
            </Carousel>
          </Section>
        : null }

        {educationContent && educationContent.learning_modules && educationContent.learning_modules.length > 0 ? 
          <Section className="page-feeds--learning-modules" >
            <h2>
              <Word value="Learning Modules" />
              <Button 
                className="btn-plain text-green"
                text="View all"
                icon={<ArrowRight />}
                Clicked={() => history.push('/education/learning-modules')}
              />
            </h2>
            <div className="page-feeds--learning-modules-grid">
              {renderLeraningModules()}
            </div>
          </Section>
        : null }
        
      </PageContent>
    </>
  );
};

export default EducationIndex;
